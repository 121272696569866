import weddingImage from './fannysamir.jpg';
import React, { useState } from 'react';
import Confetti from 'react-confetti';
import './App.css';

function Celebration() {
  const [showConfetti] = useState(true);

  return (
    <div>
      {showConfetti && <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        numberOfPieces={200}
        colors={['#DA70D6', '#AAAADD']}
        gravity={0.03}
      />}
      <Message />
    </div>
  );
}

function Message() {
  const [showContent, setShowContent] = useState(false);
  const [isImageFading, setIsImageFading] = useState(false);

  const handleImageClick = () => {
    setIsImageFading(true);
    setTimeout(() => {
      setShowContent(true);
    }, 500);
  };

  const containerStyle = {
    fontFamily: 'Times New Roman, serif',
    fontSize: 'clamp(16px, 3vw, 24px)',
    width: '90%',
    maxWidth: '800px',
    margin: '0 auto',
    textAlign: 'left',
    color: '#2F3105',
    padding: '20px'
  };

  const blueTextStyle = {
    color: '#23a6d5'  // Define the style before using it
  };

  return (
    <div className="message" style={containerStyle}>
      {!showContent && (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          cursor: 'pointer',
          opacity: isImageFading ? 0 : 1,
          transition: 'opacity 0.5s ease-out',
          width: '100%'
        }}>
          <img 
            src={weddingImage}
            alt="Wedding announcement" 
            onClick={handleImageClick}
            style={{ 
              width: '100%',
              maxWidth: '600px',
              height: 'auto',
              marginBottom: '20px'
            }} 
          />
        </div>
      )}
      
      {showContent && (
        <div style={{
          opacity: 1,
          transition: 'opacity 0.5s ease-in',
          padding: '0 15px'
        }}>
          <p>Vi ska gifta oss!</p>
          <p>I mitten av augusti är det äntligen dags. Vi tänkte fira i dagarna tre på södra Gotland och vill inget hellre än att ha dig med.</p>
          <p>Bröllopet äger rum den 16 augusti men vill du vara med på allt kul vi har planerat bör du räkna med att stanna på Gotland 15 - 17 augusti.</p>
          <p>PS. På fredagen är barn välkomna men lördag-söndag får de stanna hemma :)</p>
          <p>Massa kärlek,<br/>Fanny & Samir</p>
        </div>
      )}
    </div>
  );
}

function WeddingInfo() {
  const [language, setLanguage] = useState('sv');

  const containerStyle = {
    fontFamily: '"Jost", sans-serif',
    fontSize: '16px',
    width: '90%',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '40px 20px',
    color: 'white',
    lineHeight: '1.6'
  };

  const imageStyle = {
    width: '100%',
    maxWidth: '640px',
    height: 'auto',
    display: 'block',
    margin: '0 auto 40px auto'
  };

  const titleStyle = {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '0',
    color: 'white',
    fontFamily: "'Jost', sans-serif"
  };

  const subtitleStyle = {
    fontSize: '1rem',
    textAlign: 'center',
    marginBottom: '2rem',
    color: 'white',
    fontFamily: "'Jost', sans-serif",
    fontWeight: '400'
  };

  const sectionStyle = {
    marginBottom: '30px'
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'underline'
  };

  const bankDetailsStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: '20px',
    borderRadius: '15px',
    border: 'solid 1px rgba(0, 0, 0, 0.1)',
    marginTop: '0',
    padding: '20px'
  };

  const bankDetailsTextStyle = {
    margin: '0',  // This removes the default paragraph margin
    lineHeight: '1.6'  // Add some line spacing between the entries
  };

  const languageButtonStyle = {
    position: 'fixed',
    top: '20px',
    right: '20px',
    padding: '8px 16px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    zIndex: 1000
  };

  const sectionTitleStyle = {
    fontSize: 'clamp(18px, 3vw, 22px)',
    fontWeight: '500',
    marginBottom: '16px',
    marginTop: '24px',
    display: 'block',
    textTransform: 'none'
  };

  const dressCodeItemStyle = {
    marginBottom: '8px'  // Space between each dress code line
  };

  const dayTitleStyle = {
    fontSize: '16px',
    fontWeight: '500',
    display: 'block',
    marginBottom: '8px'
  };

  const dayContentStyle = {
    display: 'block',
    marginBottom: '16px'  // Space between different days
  };

  const sameEventContentStyle = {
    display: 'block',
    marginBottom: '4px'  // Reduced spacing for events on the same day
  };

  const eventStyle = {
    marginBottom: '16px'
  };

  const paragraphStyle = {
    marginBottom: '8px'
  };

  const closingMessageStyle = {
    fontSize: '36px',
    fontWeight: '500',
    textAlign: 'center',
    marginTop: '80px',
    marginBottom: '80px'
  };

  const noteStyle = {
    marginTop: '16px',
    marginBottom: '8px'
  };

  const pulsateStyle = {
    animation: 'pulsate 2s ease-in-out infinite',
    fontWeight: '500'  // Making it semibold for extra emphasis
  };

  const redPulsateStyle = {
    animation: 'redPulsate 4s ease-in-out infinite',
    fontWeight: '500'
  };

  const content = {
    sv: {
      title: "Samir och Fanny = Sanny och Famir 2025!",
      subtitle: "Ni har frågorna - vi har svaren. Här kommer den allra viktigaste infon inför vår bröllopshelg ❤️",
      whenAndWhere: {
        title: "När och var",
        content: "15-17 augusti på Sudret, södra Gotland"
      },
      schedule: {
        title: "Upplägg",
        friday: {
          title: "Fredag:",
          content: "16.00-23.00 poolfest, middag och bastu på"
        },
        saturday: {
          title: "Lördag:",
          content: "15.00 bröllopsceremoni vid",
          content2: "15.30-03.00 mingel, middag och fest på"
        },
        sunday: {
          title: "Söndag:",
          content: "12.00-16.00 bakishäng och pizza i"
        }
      },
      allergies: {
        content: "Har du allergier eller andra preferenser? Meddela oss genom att maila till ",
        email: "fannyochsamir@gmail.com",
        contentAfter: " (vegetarianer och pescetarianer - ni behöver inte höra av er)"
      },
      dressCode: {
        title: "Klädkod",
        friday: {
          day: "Fredag:",
          content: " sommarfin (glöm inte badkläder och handduk)"
        },
        saturday: {
          day: "Lördag:",
          content: " kostym*"
        },
        sunday: {
          day: "Söndag:",
          content: " kom som du är (glöm inte badkläder och handduk)"
        },
        note: "* = \"Något gammalt, något nytt, något lånat, något blått\" lyder den gamla traditionen som sägs bringa bruden lycka vid giftermål och skydda mot onda andar. Men.. vad skulle hända om vi tar det x 100?",
        blueNote: "Klädsel är alltså i huvudsak blå. Med en önskan om att något lånat, nytt och gammalt också kommer med i outfiten i den mån det är möjligt.",
        warning: "OBS! Tänk på att Gotland kan vara rejält blåsigt och att temperaturen har en tendens att krypa ner framåt kvällen. Eftersom vi kommer att vistas mycket utomhus - ta med något varmt att ha på dig. Och lita på oss när vi säger: skippa stilettklackarna."
      },
      toastmasters: {
        title: "Skålmästarna",
        content: "Vill du hålla tal, läsa en dikt, sjunga en sång, framföra en sketch, promota din business eller kanske trolla? Kul! Då hör du av dig till våra oerhört tillmötesgående och erfarna skålmästare, Matilda Kahl & Martin Hellborgsson. Mejla så tar de hand om dina önskemål och eventuella frågor!",
        email: "Du når dem på: ",
        emailAddress: "tal.till.fanny.och.samir@gmail.com"
      },
      accommodation: {
        title: "Boende",
        content: "Vi rekommenderar att du bokar boende på ",
        link: "Holmhällar Pensionat",
        contentAfter: ", även kallat Panget. Där finns något för alla prisklasser, från rum med våningssäng till enskilda stugor.",
        bookingInfo: "Bokningen öppnar i början av februari och du bokar genom att maila direkt till Panget. Tänk på att det här är ett gammalt hederligt familjeföretag, deras definition av service kan skilja sig från vad du är van vid - men det är ett otroligt ställe med underbart läge precis vid havet och raukar.",
        mapInfo: "Tips! Här kan du se en ",
        mapLink: "karta över stugorna",
        mapInfoAfter: ". Granen, Tallen, Valu och stugorna runt omkring dessa ligger lite avsides om man önskar det. Burgen har utsikt över raukarna :)",
        transport: {
          title: "Transporter under helgen:",
          content: "Bor du på Panget kommer vi att ordna med transport till och från alla aktiviteter under helgen. Mer information om det här kommer närmre inpå.",
          transportAlt: "Självklart går det bra att bo någon annanstans än på Panget, men då får du ansvara för din egen transport.",
          transportNote: "Vänligen meddela oss så snart som möjligt om du inte kommer att bo på Panget så vi kan planera transporterna."
        },
        ferry: {
          title: "Färja och buss:",
          carpoolInfo: "Samåkning uppmuntras! Vi kommer starta en Whatsapp-grupp för att synka eventuella behov.",
          ferryInfo: "Biljetterna till färjan har just släppts och bokas via ",
          ferryLink: "Destination Gotland",
          ferryInfoEnd: ".",
          flyInfo: "Det går också att flyga till Visby, fler avgångar kommer under våren.",
          busInfo: "Har du inte tillgång till bil går det ett begränsat antal bussar från Visby till Sudret varje dag. Här hittar du ",
          busLink: "mer information",
          busInfoCont: ", dock har inte sommartidtabellen publicerats än.",
          busDetails: "En gång om dagen går det en buss hela vägen till Panget, övriga avgångar stannar i Burgsvik som är den närmaste tätorten. Därifrån hjälps vi åt att ordna skjuts vid behov.",
          carRental: "Vill du hyra bil i Visby rekommenderar vi ",
          carRentalLink: "Mickes Biluthyrning",
          carRentalEnd: "."
        },
        gifts: {
          title: "Presenter",
          content: "Undanbedes. Den finaste gåvan är att du är med och delar den här helgen med oss. Vill du ändå ge någonting har vi båda blivit besatta av tennis och därmed börjat drömma om att få uppleva en Grand Slam-final någonstans i världen. Vi skulle helt enkelt bli oändligt glada och tacksamma för ett bidrag till vår tennisbröllopsresekassa.",
        },
        questions: {
          title: "Frågor?",
          content: "Välkommen att maila"
        },
        closing: "SES PÅ JORDENS VACKRASTE PLATS ❤️ VI LÄNGTAR EFTER DIG",
        bankDetails: {
          bank: "Swedbank",
          clearing: "Clearingnummer 8327-9",
          account: "Kontonummer 814 287 174-1",
          iban: "IBAN SE76 8000 0832 7981 4287 1741"
        }
      },
      transport: {
        title: "Transporter under helgen:",
        content: "Bor du på Panget kommer vi att ordna med transport till och från alla aktiviteter under helgen. Mer information om det här kommer närmre inpå.",
        transportAlt: "Självklart går det bra att bo någon annanstans än på Panget, men då får du ansvara för din egen transport.",
        transportNote: "Vänligen meddela oss så snart som möjligt om du inte kommer att bo på Panget så vi kan planera transporterna."
      },
      ferry: {
        title: "Färja och buss:",
        carpoolInfo: "Samåkning uppmuntras! Vi kommer starta en Whatsapp-grupp för att synka eventuella behov.",
        ferryInfo: "Biljetterna till färjan har just släppts och bokas via ",
        ferryLink: "Destination Gotland",
        ferryInfoEnd: ".",
        flyInfo: "Det går också att flyga till Visby, fler avgångar kommer under våren.",
        busInfo: "Har du inte tillgång till bil går det ett begränsat antal bussar från Visby till Sudret varje dag. Här hittar du ",
        busLink: "mer information",
        busInfoCont: ", dock har inte sommartidtabellen publicerats än.",
        busDetails: "En gång om dagen går det en buss hela vägen till Panget, övriga avgångar stannar i Burgsvik som är den närmaste tätorten. Därifrån hjälps vi åt att ordna skjuts vid behov.",
        carRental: "Vill du hyra bil i Visby rekommenderar vi ",
        carRentalLink: "Mickes Biluthyrning",
        carRentalEnd: "."
      },
      gifts: {
        title: "Presenter",
        content: "Undanbedes. Den finaste gåvan är att du är med och delar den här helgen med oss. Vill du ändå ge någonting har vi båda blivit besatta av tennis och därmed börjat drömma om att få uppleva en Grand Slam-final någonstans i världen. Vi skulle helt enkelt bli oändligt glada och tacksamma för ett bidrag till vår tennisbröllopsresekassa.",
      },
      questions: {
        title: "Har du fortfarande frågor?",
        content: "Välkommen att maila ",
        email: "fannyochsamir@gmail.com"
      },
      closing: "SES PÅ JORDENS VACKRASTE PLATS ❤️ VI LÄNGTAR EFTER DIG",
      bankDetails: {
        bank: "Swedbank",
        clearing: "Clearingnummer 8327-9",
        account: "Kontonummer 814 287 174-1",
        iban: "IBAN SE76 8000 0832 7981 4287 1741"
      }
    },
    en: {
      title: "Samir and Fanny = Sanny and Famir 2025!",
      subtitle: "You have the questions – we have the answers. Here comes the most important info ahead of our wedding weekend ❤️",
      whenAndWhere: {
        title: "When and where:",
        content: "August 15–17 on Sudret, southern Gotland"
      },
      schedule: {
        title: "Schedule",
        friday: {
          title: "Friday",
          content: "16:00–23:00 Pool party, dinner, and sauna at"
        },
        saturday: {
          title: "Saturday:",
          content: "15:00 Wedding ceremony at",
          content2: "15:30–03:00 Mingle, dinner, and party at"
        },
        sunday: {
          title: "Sunday:",
          content: "12:00–16:00 Hangover hangout and pizza at"
        }
      },
      allergies: {
        content: "Do you have any allergies or other dietary preferences? Let us know by emailing ",
        email: "fannyochsamir@gmail.com",
        contentAfter: " (vegetarians and pescatarians – no need to reach out!)"
      },
      dressCode: {
        title: "Dress Code",
        friday: {
          day: "Friday:",
          content: " Summer chic (don't forget swimwear and a towel)"
        },
        saturday: {
          day: "Saturday:",
          content: " Suit*"
        },
        sunday: {
          day: "Sunday:",
          content: " Come as you are (don't forget swimwear and a towel)"
        },
        note: "* \"Something old, something new, something borrowed, something blue\" – the old tradition is said to bring the bride good luck and ward off evil spirits. But… what if we took it x100?",
        blueNote: "The dress code is primarily blue. We also encourage you to include something borrowed, new, and old in your outfit if possible.",
        warning: "Note! Keep in mind that Gotland can be quite windy and temperatures tend to drop in the evening. Since we'll be spending a lot of time outdoors - bring something warm to wear. And trust us when we say: skip the stilettos."
      },
      toastmasters: {
        title: "Toastmasters",
        content: "Would you like to give a speech, recite a poem, sing a song, perform a sketch, promote your business, or maybe do some magic? Fun! In that case, get in touch with our incredibly accommodating and experienced toastmasters, Matilda Kahl & Martin Hellborgsson. Send them an email, and they'll take care of your requests and any questions you might have!",
        email: "You can reach them at: ",
        emailAddress: "tal.till.fanny.och.samir@gmail.com"
      },
      accommodation: {
        title: "Accommodation",
        content: "We recommend booking accommodation at ",
        link: "Holmhällar Pensionat",
        contentAfter: ", also known as Panget. They offer something for every budget, from rooms with bunk beds to private cottages.",
        bookingInfo: "Booking opens in early February, and you can reserve by emailing Panget directly. Keep in mind that this is a traditional, family-owned business, so their definition of service might differ from what you're used to – but it's an amazing place with a wonderful location right by the sea and the rauks.",
        mapInfo: "Tip! You can view a ",
        mapLink: "map of the cottages",
        mapInfoAfter: " here. Granen, Tallen, Valu, and the cottages around them are a bit more secluded if you prefer that. Burgen offers a view of the rauks :)",
        transport: {
          title: "Transport during the weekend:",
          content: "If you're staying at Panget, we'll arrange transportation to and from all activities during the weekend. More information about this will come closer to the date.",
          transportAlt: "Of course, you're welcome to stay somewhere else than Panget, but then you'll need to arrange your own transport.",
          transportNote: "Please let us know as soon as possible if you won't be staying at Panget so we can plan the transportation."
        },
        ferry: {
          title: "Ferry and bus:",
          carpoolInfo: "Carpooling is encouraged! We will create a WhatsApp group to coordinate any needs.",
          ferryInfo: "Ferry tickets have just been released and can be booked through ",
          ferryLink: "Destination Gotland",
          ferryInfoEnd: ".",
          flyInfo: "You can also fly to Visby, with more departures available in the spring.",
          busInfo: "If you don't have access to a car, a limited number of buses run from Visby to Sudret every day. ",
          busLink: "More information",
          busInfoCont: " can be found here, but please note that the summer timetable has not been published yet.",
          busDetails: "Once a day, there is a bus that goes all the way to Panget. Other departures stop in Burgsvik, the nearest town. From there, we'll help arrange rides if needed.",
          carRental: "If you'd like to rent a car in Visby, we recommend ",
          carRentalLink: "Mickes Biluthyrning",
          carRentalEnd: "."
        },
        gifts: {
          title: "Gifts",
          content: "No gifts, please. The greatest gift is having you with us to share this weekend.",
          tennis: "However, if you still wish to give something, we've both become obsessed with tennis and have started dreaming of experiencing a Grand Slam final somewhere in the world. We would be infinitely happy and grateful for any contribution to our tennis-honeymoon-fund."
        },
        questions: {
          title: "Questions?",
          content: "Feel free to email us at"
        },
        closing: "SEE YOU AT THE MOST BEAUTIFUL PLACE ON EARTH ❤️ WE CAN'T WAIT TO SEE YOU!",
        bankDetails: {
          bank: "Bank: Swedbank",
          clearing: "Clearing number 8327-9",
          account: "Account number 814 287 174-1",
          iban: "IBAN SE76 8000 0832 7981 4287 1741"
        }
      },
      transport: {
        title: "Transporter under helgen:",
        content: "Bor du på Panget kommer vi att ordna med transport till och från alla aktiviteter under helgen. Mer information om det här kommer närmre inpå.",
        transportAlt: "Självklart går det bra att bo någon annanstans än på Panget, men då får du ansvara för din egen transport.",
        transportNote: "Vänligen meddela oss så snart som möjligt om du inte kommer att bo på Panget så vi kan planera transporterna."
      },
      ferry: {
        title: "Ferry and bus:",
        carpoolInfo: "Carpooling is encouraged! We will create a WhatsApp group to coordinate any needs.",
        ferryInfo: "Ferry tickets have just been released and can be booked through ",
        ferryLink: "Destination Gotland",
        ferryInfoEnd: ".",
        flyInfo: "You can also fly to Visby, with more departures available in the spring.",
        busInfo: "If you don't have access to a car, a limited number of buses run from Visby to Sudret every day. ",
        busLink: "More information",
        busInfoCont: " can be found here, but please note that the summer timetable has not been published yet.",
        busDetails: "Once a day, there is a bus that goes all the way to Panget. Other departures stop in Burgsvik, the nearest town. From there, we'll help arrange rides if needed.",
        carRental: "If you'd like to rent a car in Visby, we recommend ",
        carRentalLink: "Mickes Biluthyrning",
        carRentalEnd: "."
      },
      gifts: {
        title: "Gifts",
        content: "No gifts, please. The greatest gift is having you with us to share this weekend.",
        tennis: "However, if you still wish to give something, we've both become obsessed with tennis and have started dreaming of experiencing a Grand Slam final somewhere in the world. We would be infinitely happy and grateful for any contribution to our tennis-honeymoon-fund."
      },
      questions: {
        title: "Do you still have any questions??",
        content: "Feel free to email us at ",
        email: "fannyochsamir@gmail.com"
      },
      closing: "SEE YOU AT THE MOST BEAUTIFUL PLACE ON EARTH ❤️ WE CAN'T WAIT TO SEE YOU!",
      bankDetails: {
        bank: "Bank: Swedbank",
        clearing: "Clearing number 8327-9",
        account: "Account number 814 287 174-1",
        iban: "IBAN SE76 8000 0832 7981 4287 1741"
      }
    }
  };

  const appStyle = {
    minHeight: '100vh',
    background: 'linear-gradient(180deg, #E86B9A 0%, #5BA7D1 100%)',
    fontFamily: "'Jost', sans-serif"
  };

  const contentStyle = {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    color: 'white',
    fontFamily: "'Jost', sans-serif"
  };

  return (
    <div className="App" style={appStyle}>
      <div style={contentStyle}>
        <h1 style={titleStyle}>{content[language].title}</h1>
        <p style={subtitleStyle}>{content[language].subtitle}</p>
        <button 
          onClick={() => setLanguage(language === 'sv' ? 'en' : 'sv')}
          style={languageButtonStyle}
        >
          {language === 'sv' ? 'EN' : 'SV'}
        </button>

        <img 
          src={weddingImage} 
          alt="Fanny and Samir" 
          style={imageStyle}
        />

        <h2 style={sectionTitleStyle}>{content[language].whenAndWhere.title}</h2>
        <p>{content[language].whenAndWhere.content}</p>

        <h2 style={sectionTitleStyle}>{content[language].schedule.title}</h2>
        
        <div style={{ marginBottom: '16px' }}>
          <span style={dayTitleStyle}>{content[language].schedule.friday.title}</span>
          <span style={dayContentStyle}>
            {content[language].schedule.friday.content} <a href="https://maps.app.goo.gl/t3mD5NUiWU7vd6VU6" style={linkStyle}>Sundre Västergårde</a>
          </span>
        </div>
        
        <div style={{ marginBottom: '16px' }}>
          <span style={dayTitleStyle}>{content[language].schedule.saturday.title}</span>
          <span style={sameEventContentStyle}>
            {content[language].schedule.saturday.content} <a href="https://maps.app.goo.gl/kxcbKJar9S38Cha16" style={linkStyle}>Hoburgen</a>
          </span>
          <span style={dayContentStyle}>
            {content[language].schedule.saturday.content2} <a href="https://majstre.se/" style={linkStyle}>Majstregården</a>
          </span>
        </div>
        
        <div style={{ marginBottom: '16px' }}>
          <span style={dayTitleStyle}>{content[language].schedule.sunday.title}</span>
          <span style={dayContentStyle}>
            {content[language].schedule.sunday.content} <a href="https://burgsvikshavsbastu.se/" style={linkStyle}>Burgsviks Havsbastu</a>
          </span>
        </div>

        <h2 style={sectionTitleStyle}>{content[language].allergies.title}</h2>
        <p>
          {content[language].allergies.content}
          <a href="mailto:fannyochsamir@gmail.com" style={linkStyle}>
            {content[language].allergies.email}
          </a>
          {content[language].allergies.contentAfter}
        </p>

        <h2 style={sectionTitleStyle}>{content[language].dressCode.title}</h2>
        
        <div style={dressCodeItemStyle}>
          <span style={{ fontWeight: '500' }}>{content[language].dressCode.friday.day}</span>
          {content[language].dressCode.friday.content}
        </div>
        
        <div style={dressCodeItemStyle}>
          <span style={{ fontWeight: '500' }}>{content[language].dressCode.saturday.day}</span>
          {content[language].dressCode.saturday.content}
        </div>

        <p style={noteStyle}>{content[language].dressCode.note}</p>
        
        <p style={noteStyle}>
          {content[language].dressCode.blueNote}
        </p>
        
        <div style={dressCodeItemStyle}>
          <span style={{ fontWeight: '500' }}>{content[language].dressCode.sunday.day}</span>
          {content[language].dressCode.sunday.content}
        </div>

        <p style={noteStyle}>{content[language].dressCode.warning}</p>

        <h2 style={sectionTitleStyle}>{content[language].toastmasters.title}</h2>
        <p>
          {content[language].toastmasters.content}
        </p>
        <p style={{ marginTop: '16px' }}>
          {content[language].toastmasters.email}
          <a href="mailto:tal.till.fanny.och.samir@gmail.com" style={linkStyle}>
            {content[language].toastmasters.emailAddress}
          </a>
        </p>

        <h2 style={sectionTitleStyle}>{content[language].accommodation.title}</h2>
        <p>
          {content[language].accommodation.content}
          <a href="https://www.holmhallar.se/" target="_blank" rel="noopener noreferrer" style={linkStyle}>
            {content[language].accommodation.link}
          </a>
          {content[language].accommodation.contentAfter}
        </p>
        <p>{content[language].accommodation.bookingInfo}</p>
        <p>
          {content[language].accommodation.mapInfo}
          <a href="https://www.holmhallar.se/kartor/" target="_blank" rel="noopener noreferrer" style={linkStyle}>
            {content[language].accommodation.mapLink}
          </a>
          {content[language].accommodation.mapInfoAfter}
        </p>
        <h2 style={sectionTitleStyle}>{content[language].accommodation.transport.title}</h2>
        <p>{content[language].accommodation.transport.content}</p>
        <p>{content[language].accommodation.transport.transportAlt}</p>
        <p style={redPulsateStyle}>{content[language].accommodation.transport.transportNote}</p>

        <h2 style={sectionTitleStyle}>{content[language].ferry.title}</h2>
        <p>{content[language].ferry.carpoolInfo}</p>
        <p>
          {content[language].ferry.ferryInfo}
          <a href="https://www.destinationgotland.se/sv/" target="_blank" rel="noopener noreferrer" style={linkStyle}>
            {content[language].ferry.ferryLink}
          </a>
          {content[language].ferry.ferryInfoEnd}
        </p>
        <p>{content[language].ferry.flyInfo}</p>
        <p>
          {content[language].ferry.busInfo}
          <a href="https://gotland.se/trafik-gator-och-parker/kollektivtrafik/busstidtabeller-och-linjekartor" target="_blank" rel="noopener noreferrer" style={linkStyle}>
            {content[language].ferry.busLink}
          </a>
          {content[language].ferry.busInfoCont}
        </p>
        <p>{content[language].ferry.busDetails}</p>
        <p>
          {content[language].ferry.carRental}
          <a href="https://www.mickesbiluthyrning.se/" target="_blank" rel="noopener noreferrer" style={linkStyle}>
            {content[language].ferry.carRentalLink}
          </a>
          {content[language].ferry.carRentalEnd}
        </p>

        <h2 style={sectionTitleStyle}>{content[language].gifts.title}</h2>
        <p>{content[language].gifts.content}</p>
        <p>{content[language].gifts.tennis}</p>
        
        <div style={{ marginTop: '24px' }}>
          <div style={bankDetailsStyle}>
            <p style={bankDetailsTextStyle}>
              {content[language].bankDetails.bank}<br/>
              {content[language].bankDetails.clearing}<br/>
              {content[language].bankDetails.account}<br/>
              {content[language].bankDetails.iban}
            </p>
          </div>
        </div>

        <h2 style={sectionTitleStyle}>{content[language].questions.title}</h2>
        <p>
          {content[language].questions.content}
          <a href="mailto:fannyochsamir@gmail.com" style={linkStyle}>
            {content[language].questions.email}
          </a>
        </p>

        <p style={closingMessageStyle}>
          {content[language].closing}
        </p>
      </div>
    </div>
  );
}

export default WeddingInfo;
